
import { defineComponent } from 'vue'

import DefaultPopup from '@/components/molecules/DefaultPopup.vue'
import BarriersCard from '@/components/molecules/card/BarriersCard.vue'
import SlotCard from '@/components/molecules/card/SlotCard.vue'

import Edit from '@/assets/svg/edit.svg?inline'
import Delete from '@/assets/svg/delete.svg?inline'
import { Vue3Lottie } from 'vue3-lottie'
import AlertBlock from '@/components/molecules/AlertBlock.vue'

export default defineComponent({
  name: 'UnitBigCardSection',
  data () {
    return {
      buildingData: {},
      isModalAddSlots: false,
      buidingId: null,
      isModalDelete: false,
      unitData: {},
      propertyMeta: null,
      page: 1,
      isModalDeleteUnit: false,
      coming_soon: false,
      moreInfo: false,
      unitId: '',
      isLoading: true,
      isModalPublish: false,
      statusUnit: '',
      errorUnpublishedUnit: null,
      errorNewUnpublishedUnit: null,
      timeslots: {},
      isSlotsError: true,
      isModalUnitPublished: null,
      errorUnitDraft: null,
      isModalEditUnit: false,
      errorUpublishPopup: false,
      errorUpublish: null,
      inActiveVisitPopup: false,
      inActiveHandover: false
    }
  },
  mounted () {
    const lang = JSON.parse(localStorage.getItem('lang'))
    this.activeLang = lang
    this.unitId = this.$route.query.unitId
    this.getUnit()
  },
  components: {
    DefaultPopup,
    Edit,
    Delete,
    BarriersCard,
    SlotCard,
    LottieAnimation: Vue3Lottie,
    AlertBlock
  },
  methods: {
    getUnit () {
      this.$store.dispatch('property/getUnit', this.unitId).then(
        (res) => {
          this.unitData = res.data
          this.isLoading = false
          this.getSlots()
        },
        (error) => {
          this.error = error.response.data.message
          this.isLoading = false
        }
      )
    },
    getSlots () {
      this.$store.dispatch('property/getSlots', this.unitId).then(
        (res) => {
          this.timeslots = res.data
        },
        (error) => {
          this.error = error.response.data.message
        }
      )
    },
    publishUnit (data) {
      this.isLoading = true
      data = {
        unitId: this.unitId
      }
      data.handover_cancel = true
      this.$store.dispatch('property/publishUnit', data).then(
        (res) => {
          this.getUnit()
          this.statusUnit = ''
          this.isModalPublish = false
          this.isLoading = false
        },
        (error) => {
          this.error = error.response.data.message
          this.errorNewUnpublishedUnit = error.response.data
          this.errorUnitDraft = error.response.data.context
          this.isLoading = false
          this.isSlotsError = false
        }
      )
    },
    unpublishedUnit () {
      this.isLoading = true
      this.$store.dispatch('property/unpublishedUnit', this.unitId).then(
        (res) => {
          this.getUnit()
          this.statusUnit = ''
          this.isModalPublish = false
          this.isLoading = false
        },
        (error) => {
          this.errorUnpublishedUnit = error.response.data.errors
          this.errorNewUnpublishedUnit = error.response.data.message
          this.isLoading = false
          this.errorUpublish = error.response.data.message
          if (this.errorUpublish === 'Unit has active visit') {
            this.errorUpublishPopup = true
            this.isModalPublish = false
            this.errorUnpublishedUnit = null
            this.errorNewUnpublishedUnit = null
          }
        }
      )
    },
    handleCloseUnpublishVisitUnitModal () {
      this.errorUpublishPopup = false
      this.isModalPublish = false
    },
    deleteUnit () {
      this.$store.dispatch('property/deleteUnit', this.unitId).then(
        (res) => {
          this.isModalDeleteUnit = false
          this.$router.push('/dashboard/property')
        },
        (error) => {
          this.error = error.response.data.message
        }
      )
    },
    handleOpenModalAddSlots () {
      this.isModalAddSlots = true
    },
    handleCloseModalAddSlots () {
      this.isModalAddSlots = false
    },
    handleOpenUnitModal (id) {
      this.isModalDeleteUnit = true
    },
    openDeleteUnit () {
      this.isModalDelete = true
    },
    handleCloseModalDelete () {
      this.isModalDelete = false
      this.isModalDeleteUnit = false
    },
    goToCreateSlots () {
      this.$router.push(`/dashboard/create-slots?unitId=${this.unitId}`)
    },
    editUnitPopup () {
      if (this.unitData?.status?.value !== 'published') {
        this.isModalEditUnit = true
      } else {
        if (this.unitData?.in_active_visit) {
          this.inActiveVisitPopup = true
        } else this.editUnit()
      }
    },
    editUnit () {
      if (this.unitData?.status?.value !== 'published') {
        this.$router.push(`/dashboard/create-unit-step-one?unitId=${this.unitId}`)
      } else {
        this.isModalUnitPublished = true
      }
    },
    handleCloseModalisModalUnitPublished () {
      this.isModalUnitPublished = false
    },
    handleCloseinActiveVisitModal () {
      this.inActiveVisitPopup = false
    },
    goToFourStep () {
      this.$router.push(
        `/dashboard/create-unit-step-four?unitId=${this.unitId}`
      )
    },
    publishUnitPopup (action, inActiveHandover) {
      this.inActiveHandover = inActiveHandover
      this.isModalPublish = true
      this.statusUnit = action
    },
    handleCloseModalPublish () {
      this.isModalPublish = false
      this.errorNewUnpublishedUnit = null
    },
    handleCloseModalisModalEditUnit () {
      this.isModalEditUnit = false
    }
  }
})
